import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "./layout";

const RoomOrderLogin = () => {
  const navigator = useNavigate();
  const getWebId = localStorage.getItem("webId");

  const [inputWebId, setInputWebId] = useState("");
  const [inputWebPw, setInputWebPw] = useState("");

  const [locationLogin, setLocationLogin] = useState(true); // footer 스타일 변경

  // 담다페이에서 넘어오는 쿼리스트링 처리 (첫 로그인 시)
  const [isInitLogin, setIsInitLogin] = useState(true);
  const responseInfo = new URLSearchParams(location.search);
  const resStCode = responseInfo.get("stcode");
  const resWebId = responseInfo.get("webid");
  const resWebPw = responseInfo.get("webpw");

  const [isInitCheck, setIsInitCheck] = useState(
    localStorage.getItem("isInitCheck")
      ? localStorage.getItem("isInitCheck")
      : "false"
  ); // 체크박스 체크 여부

  // 로그인
  const handleLogin = async (e) => {
    if (!isInitLogin) {
      e.preventDefault();
    }

    let webId = responseInfo.size === 0 ? inputWebId : resWebId;
    let webPw = responseInfo.size === 0 ? inputWebPw : resWebPw;

    fetch("/api/user/roomorder/confirm ", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        web_id: webId,
        web_pass: webPw,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setLocationLogin(false);

          // 파라미터가 존재 여부에 따른 저장값 세팅
          let stCode = responseInfo.size === 0 ? res.data.st_code : resStCode;
          localStorage.setItem("stCode", stCode);
          localStorage.setItem("webId", webId);
          localStorage.setItem("custName", res.data.cust_name);
          localStorage.setItem("checkedLogin", "Y");
          localStorage.setItem("isRoomOrder", "Y");

          webId === "admin" ? navigator("/manager") : navigator("/user");
        } else {
          alert("아이디 또는 비밀번호를 확인해주세요.");
        }
      })
      .catch((error) => console.error(error));
  };

  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin(e);
    }
  };

  useEffect(() => {
    if (responseInfo.size === 0) {
      setIsInitLogin(false);

      if (isInitCheck === "true") {
        setInputWebId(getWebId);
      }

      // 로그인이 되어있는 경우 /services 경로로 이동
      if (localStorage.getItem("checkedLogin") === "Y") {
        localStorage.getItem("webId") === "admin"
          ? navigator("/manager")
          : navigator("/user");
      }
    } else {
      handleLogin();
    }

    return () => {
      setLocationLogin(false);
    };
  }, []);

  // 체크박스 체크시마다 로컬 스토리지에 바로 반영
  useEffect(() => {
    localStorage.setItem("isInitCheck", isInitCheck);
  }, [isInitCheck]);

  return (
    <Fragment>
      {!isInitLogin && (
        <div className="bg-gradient-primary pc">
          <div className="login-bg"></div>
          <div className="container login">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-6">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6 d-none d-lg-block logo-image"></div>
                      <div className="col-lg-6">
                        <div className="p-5 c-pp">
                          <div className="c-logo-image"></div>
                          <h3 className="mb-4 login-title">담다 CRM</h3>
                          <form className="user" onSubmit={handleLogin}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-user"
                                name="admin_id"
                                id="id"
                                placeholder="ID"
                                required
                                defaultValue={inputWebId ? inputWebId : ""}
                                onKeyDown={keyDownFnc}
                                onChange={(e) => setInputWebId(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control form-control-user"
                                name="admin_password"
                                id="exampleInputPassword"
                                placeholder="Password"
                                required
                                onKeyDown={keyDownFnc}
                                onChange={(e) => setInputWebPw(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox small">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck"
                                  defaultChecked={
                                    isInitCheck === "true" ? "checked" : ""
                                  }
                                  onChange={(e) =>
                                    setIsInitCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck"
                                >
                                  아이디 저장
                                </label>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn c-btn-primary btn-primary btn-user btn-block"
                            >
                              Login
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer locationLogin={locationLogin} />
    </Fragment>
  );
};

export default RoomOrderLogin;
